import React, { PropsWithChildren } from "react";

import styles from "./styles.module.scss";

export enum ContainerSize {
  /** 4 design system columns, 23rem, 368px. Too small. You probably don't want this. */
  XSmall = "x-small",
  /** 6 design system columns, 35rem, 560px. This feels like a normal size for a modal dialog, and we use it for the slim pages that are not much more than a basic form. */
  Small = "small",
  /** 8 design system columns, 47rem, 752px. Normal for a page, wide for a dialog. */
  Medium = "medium",
  /** 10 design system columns, 59rem, 944px. Wide for a page, the de-facto maximum dialog size. */
  Large = "large",
  /** 12 design system columns, 71rem, 1136px. Very wide for a page, ridiculous for a dialog, probably don't use it for dialogs. */
  Max = "max",
  /** Unset auto size. Sometimes useful for dialogs with unknown content width. We don't use this for pages. */
  Auto = "auto",
}

const ContainerSizes = {
  [ContainerSize.XSmall]: styles["x-small"],
  [ContainerSize.Small]: styles.small,
  [ContainerSize.Medium]: styles.medium,
  [ContainerSize.Large]: styles.large,
  [ContainerSize.Max]: styles.max,
  [ContainerSize.Auto]: styles.auto,
};

export type ContainerProps = PropsWithChildren<{
  size?: ContainerSize;
  className?: string;
}>;

export default function Container({ size = ContainerSize.Max, children, className }: ContainerProps) {
  return <div className={`${styles.Container} ${ContainerSizes[size]} ${className ?? ""}`}>{children}</div>;
}
