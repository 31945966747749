import linkStyles from "./link.module.scss";

export type LinkClassProps = {
  variant?: string;
  className?: string;
  isQuiet?: boolean;
  isOverBg?: boolean;
};

export function linkClassName({ className = "", variant = "primary", isQuiet, isOverBg }: LinkClassProps) {
  return `
      ${linkStyles.Link}
      ${linkStyles[variant]}
      ${className ?? ""}
      ${isQuiet ? linkStyles.quiet : ""}
      ${isOverBg ? linkStyles.overBg : ""}
    `;
}
