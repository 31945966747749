/** Returns fileSize and units as a strings */
export function displayFileSize(bytes: number): string {
  const kiloBytes = 1024;
  const megaBytes = kiloBytes * kiloBytes; // One MB is 1024 KB
  const gigaBytes = kiloBytes * megaBytes; // One GB is 1024 MB

  let value = bytes;
  let unit = " bytes";

  if (bytes < kiloBytes) {
    unit = " bytes";
    value = bytes;
  } else if (bytes < megaBytes) {
    unit = "kB";
    value = parseFloat((bytes / kiloBytes).toFixed(1));
  } else if (bytes < gigaBytes) {
    unit = "MB";
    value = parseFloat((bytes / megaBytes).toFixed(2));
  } else {
    unit = "GB";
    value = parseFloat((bytes / gigaBytes).toFixed(2));
  }

  return `${value}${unit}`;
}
