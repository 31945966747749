import React from "react";
import { renderToString } from "react-dom/server";

import { Theme } from "../components/theme";
import BlockEditorContent, { BlockEditorContentInline } from "./react";
import { sanitise, validateRichTextDocumentOrThrow } from "./validate";

export function validatedHtmlOrLexical(data: string) {
  if (data[0] === "{") return validateRichTextDocumentOrThrow(data);
  return sanitise(data);
}

/** Returns the HTML as a string, if it is HTML and it passes validation, otherwise renders the data to an HTML string as Lexical (again if it passes validation) */
export function renderedHtmlOrLexical(document: string, theme: Theme) {
  const validated = validatedHtmlOrLexical(document);
  if (typeof validated === "string") return validated;
  return renderToString(<BlockEditorContent content={validated} theme={theme} />);
}

/** Returns the HTML as a string, otherwise renders valid HTML string as inline lexical  */
export function renderedInlineHtmlOrLexical(document: string) {
  const validated = validatedHtmlOrLexical(document);
  if (typeof validated === "string") return validated;
  return renderToString(<BlockEditorContentInline content={validated} />);
}
