import React, { ChangeEvent, Fragment, MouseEvent as ReactMouseEvent, useCallback, useEffect, useState } from "react";

import { AnswerTemplate } from "../../db/models/Answer";
import Question from "../../db/models/Question";
import { renderedInlineHtmlOrLexical } from "../../shared/block-editor-data/html-or-lexical";
import { useLanguage } from "../../shared/components/translation";
import { Translated, translateDbString } from "../translation/Translated";
import { useTranslate } from "../translation/frontend";
import ErrorMessage from "./ErrorMessage";
import TextInput from "./TextInput";
import AccordionIcon from "./icons/AccordionIcon";
import CheckIcon from "./icons/CheckIcon";
import useInvalidQuestions, { useInputValidity } from "./useInvalidQuestions";

interface QuestionOptionsProps {
  question: Question;
  answers: AnswerTemplate[];
}

export default function QuestionOptions({ question, answers }: QuestionOptionsProps) {
  const language = useLanguage();
  const translate = useTranslate();
  const { markValid, markInvalid } = useInputValidity({ question });
  const { removeWarning } = useInvalidQuestions();

  const optionGroups = question.optionGroups!;
  const inputType = question.questionType === "RADIO" ? "radio" : "checkbox";

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    answers.filter((answer) => answer.optionText).map((answer) => answer.optionId!),
  );

  const [openAccordions, setOpenAccordions] = useState<string[]>(
    optionGroups
      .filter((optionGroup) => optionGroup.options.some(({ id }) => selectedOptions.includes(id)))
      .map(({ id }) => id),
  );

  const toggleAccordion = useCallback(
    (groupId: string) => (ev: ReactMouseEvent) => {
      ev.preventDefault();
      setOpenAccordions(
        openAccordions.includes(groupId)
          ? openAccordions.filter((openAccordion) => openAccordion !== groupId)
          : openAccordions.concat([groupId]),
      );
    },
    [openAccordions],
  );

  const clearOtherBox = (optionId: string) => {
    const otherInput = document.getElementById(`${optionId}-other`) as HTMLInputElement | null;
    if (otherInput) otherInput.value = "";
  };

  const findOptionById = useCallback(
    (id: string) => {
      for (const group of optionGroups) {
        for (const option of group.options) {
          if (option.id === id) {
            return option;
          }
        }
      }
    },
    [optionGroups],
  );

  const changeOption = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      if (question.questionType === "RADIO" && ev.currentTarget.checked) {
        for (const option of selectedOptions) clearOtherBox(option);
        setSelectedOptions([ev.currentTarget.id]);
        markValid();
      } else if (question.questionType === "CHECKLIST") {
        if (!ev.currentTarget.checked) {
          // Remove the 'other' box and any warnings associated with it when the checkbox is cleared
          const optionId = ev.currentTarget.id;
          const option = findOptionById(optionId);
          if (option) removeWarning({ question, option });
          clearOtherBox(optionId);
        }
        setSelectedOptions(
          ev.currentTarget.checked
            ? [...selectedOptions, ev.currentTarget.id]
            : selectedOptions.filter((id) => id !== ev.currentTarget.id),
        );
        markValid();
      }
    },
    [question, markValid, selectedOptions, findOptionById, removeWarning],
  );

  useEffect(() => {
    if (!question.required) return;
    const firstOption = optionGroups[0]?.options[0]?.id;
    if (!firstOption) return;
    // TypeScript doesn't like using refs here, but this is fine:
    const firstInput = document.getElementById(firstOption) as HTMLInputElement;
    if (!firstInput) return;
    firstInput.setCustomValidity(selectedOptions.length > 0 ? "" : translate("required"));
  }, [selectedOptions, question.required, optionGroups, translate]);

  const handleOnInvalid = useCallback(() => markInvalid(), [markInvalid]);

  return (
    <>
      <ErrorMessage field={{ question }} />
      <div className="options">
        {optionGroups.map((optionGroup) => (
          <div
            key={optionGroup.id}
            className={`options__group ${optionGroup.collapsible ? "options__group--collapsible" : ""} ${
              openAccordions.includes(optionGroup.id) ? "options__group--open" : ""
            }`}
          >
            {optionGroup.groupName ? (
              <h3
                className={`options__subtitle ${optionGroup.collapsible ? "options__subtitle--collapsible" : ""}`}
                onClick={optionGroup.collapsible ? toggleAccordion(optionGroup.id) : undefined}
              >
                {optionGroup.collapsible ? (
                  <button
                    className="options__collapse"
                    aria-expanded={openAccordions.includes(optionGroup.id) ? true : false}
                  >
                    <Translated translations={optionGroup.groupNameTranslations}>{optionGroup.groupName}</Translated>

                    <span className="options__collapse-icon">
                      <AccordionIcon />
                    </span>
                  </button>
                ) : (
                  <Translated translations={optionGroup.groupNameTranslations}>{optionGroup.groupName}</Translated>
                )}
              </h3>
            ) : null}
            <div className="options__options">
              {optionGroup.options.map((option) => {
                const value = translateDbString(option.valueTranslations, language) ?? option.value;
                const answer = answers.find((answer) => answer.optionId === option.id);
                const selected = selectedOptions.includes(option.id);
                const otherDescription =
                  translateDbString(option.otherDescriptionTranslations, language) ?? option.otherDescription;
                return (
                  <Fragment key={option.id}>
                    <div className="options__option">
                      <div className="options__label">
                        <input
                          type={inputType}
                          className={`form-control options__check options__${inputType}`}
                          id={option.id}
                          name={question.id}
                          value={JSON.stringify({ optionId: option.id, optionText: option.value })}
                          defaultChecked={selected}
                          onChange={changeOption}
                          required={question.required && inputType === "radio"}
                          onInvalid={handleOnInvalid}
                        />
                        {inputType === "checkbox" ? <CheckIcon /> : null}
                        <div className="options__label-target">
                          <label
                            htmlFor={option.id}
                            aria-describedby={option.definition ? `options__definition-${option.id}` : undefined}
                          >
                            {value}
                            {option.suffix ? (
                              <span className="options__label-suffix">
                                {" "}
                                <Translated translations={option.suffixTranslations}>{option.suffix}</Translated>
                              </span>
                            ) : null}
                          </label>
                          {option.onlyShowDefinitionWhenSelected && option.definition ? (
                            <div
                              className={`options__label-response content-styles allow-cross-form-links ${
                                selected ? "is-visible" : ""
                              }`}
                              id={`options__definition-${option.id}`}
                              dangerouslySetInnerHTML={{
                                __html: renderedInlineHtmlOrLexical(
                                  translateDbString(option.definitionTranslations, language) ?? option.definition,
                                ),
                              }}
                            />
                          ) : option.definition ? (
                            <div
                              className="options__label-hint content-styles"
                              id={`options__definition-${option.id}`}
                              dangerouslySetInnerHTML={{
                                __html: renderedInlineHtmlOrLexical(
                                  translateDbString(option.definitionTranslations, language) ?? option.definition,
                                ),
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {option.allowOther ? (
                      <div className={`options__other ${selected ? "is-visible" : ""}`}>
                        <label className="hidden" htmlFor={`${option.id}-other`}>
                          {otherDescription || translate("otherPleaseSpecify")}
                        </label>
                        <TextInput field={{ question, option }} defaultValue={answer?.freeText} hidden={!selected} />
                      </div>
                    ) : null}
                  </Fragment>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
