import React, { FormEvent, PropsWithChildren, createContext, useMemo, useRef } from "react";

import { ContainerSize } from "../../../../dashboard/src/components/design-system/Container";
import LoadingOverlay from "../../../../dashboard/src/components/design-system/LoadingOverlay";
import useGeneratedId from "../../../hooks/useGeneratedId";
import { Translation } from "../../translation";
import Modal, { ModalPropsBase } from "../Modal";
import styles from "./styles.module.scss";

export type DialogProps = PropsWithChildren<
  ModalPropsBase & {
    className?: string;
    isLoading?: boolean;
    size?: ContainerSize;
    fullHeight?: boolean;
    loadingText?: string;
    id?: string;
  }
>;

export const dialogContext = createContext<
  | { dialogId: string; onClickClose?: () => void; titleRef: React.RefObject<HTMLHeadingElement> }
  | { dialogId: null; onClickClose: null; titleRef: null }
>({ dialogId: null, onClickClose: null, titleRef: null });

const ContainerSizes = {
  [ContainerSize.XSmall]: styles["x-small"],
  [ContainerSize.Small]: styles.small,
  [ContainerSize.Medium]: styles.medium,
  [ContainerSize.Large]: styles.large,
  [ContainerSize.Max]: styles.max,
  [ContainerSize.Auto]: styles.auto,
};

function Dialog({
  children,
  className,
  closeOnClickOutside,
  isLoading,
  isOpen,
  onClose,
  onClickClose,
  size = ContainerSize.Small,
  fullHeight,
  loadingText,
  id,
  alert,
}: DialogProps) {
  const dialogId = useGeneratedId(id);
  const titleRef = useRef<HTMLHeadingElement>(null);

  // Prevent submit events from bubbling up to parent forms, or causing a full postback:
  const onSubmit = useMemo(
    () => (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    [],
  );

  return (
    <dialogContext.Provider value={{ dialogId, onClickClose: onClickClose ?? onClose, titleRef }}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onClickClose={onClickClose}
        dimBackground
        closeOnClickOutside={closeOnClickOutside}
        alert={alert}
        className={styles.DialogContainer}
        labelledBy={`modal-${dialogId}-title`}
        labelRef={titleRef}
        isLeaf={false}
      >
        {isOpen && (
          <div className={styles.Aligner}>
            <div
              className={`
                ${styles.Dialog}
                ${className ?? ""}
                ${ContainerSizes[size]}
                ${fullHeight ? styles.FullHeight : ""}
                ${isLoading ? styles.IsLoading : ""}
              `}
              onSubmit={onSubmit as ((e: FormEvent<HTMLElement>) => void) | undefined}
            >
              <div className={styles.Main} tabIndex={0}>
                {isLoading && (
                  <LoadingOverlay visible={isLoading || false}>
                    <Translation props={loadingText} />
                  </LoadingOverlay>
                )}
                {children}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </dialogContext.Provider>
  );
}

export default Dialog;
